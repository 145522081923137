export enum ExtendFilterOperator {
  END = 'end',
  EQ = 'eq',
  ILIKE = 'ilike',
  LIKE = 'like',
  NEQ = 'neq',
  NLIKE = 'nlike',
  ST = 'st',
}

export enum DateFilterOperator {
  AFTER = 'after',
  BEFORE = 'before',
  STRICTLY_AFTER = 'strictly_after',
  STRICTLY_BEFORE = 'strictly_before',
}

export type FilterOperatorType = ExtendFilterOperator | DateFilterOperator;
export const FilterOperatorLabelMap: { [key in FilterOperatorType]: string } = {
  [ExtendFilterOperator.END]: 'ends with',
  [ExtendFilterOperator.EQ]: 'is equal to',
  [ExtendFilterOperator.ILIKE]: 'is ilike',
  [ExtendFilterOperator.LIKE]: 'is like',
  [ExtendFilterOperator.NEQ]: 'not equal to',
  [ExtendFilterOperator.NLIKE]: 'is not like',
  [ExtendFilterOperator.ST]: 'starts with',
  [DateFilterOperator.AFTER]: 'after',
  [DateFilterOperator.BEFORE]: 'before',
  [DateFilterOperator.STRICTLY_AFTER]: 'strictly after',
  [DateFilterOperator.STRICTLY_BEFORE]: 'strictly before',
}

export interface FilterOperator {
  operator: ExtendFilterOperator | DateFilterOperator;
  name: string;
}
