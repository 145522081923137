import { Filter } from '@core/services/filter/filter';
import {
  ApiFilterOperatorResponse,
  ApiFilterPropertyResponse,
} from '@core/services/api/types';
import { DateFilterOperator } from '@core/services/filter/filter-operator';

export function toDateFilter(
  propertyResponse: ApiFilterPropertyResponse,
  operatorResponse: ApiFilterOperatorResponse
): Filter[] {
  if (!propertyResponse || !operatorResponse) {
    return [];
  }
  return Object.keys(propertyResponse).map(key => {
    return {
      property: {
        name: key,
        config: propertyResponse[key],
      },
      operators: Object.keys(operatorResponse).map(operatorName => {
        return {
          // @ts-ignore
          operator: DateFilterOperator[operatorName.toUpperCase()],
          name: operatorName,
        };
      }),
    };
  });
}
